<template>

  <DefaultCrudView
    @create-new="newTheme"
    :breadcrumbsItems="breadcrumbs"
    :actions="isMaster ? actions : []"
    containerLarge
  >
    <template slot="content">

      <v-data-table
        :headers="headers"
        :items="themes"
        :loading="loading"
        :options.sync="options"
        :server-items-length="itemsLength"
        :footer-props="$defaultDataTableFooterProps"
        class="elevation-1"
      >
        <template v-slot:item.tenant_id="{ item }">
          {{ getTenantTitle(item.tenant_id) }}
        </template>

        <template v-slot:item.actions="{ item }">

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click.stop="editTheme(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>

          <v-tooltip v-if="isMaster" right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="modalDelete(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t('delete') }}</span>
          </v-tooltip>

        </template>
      </v-data-table>

      <DefaultModal
        :title="getModalTitle()"
        ref="modal"
        @submit="save"
        :saveButtonDisabled="! isMaster"
      >
        <template v-slot:content>
          <DynamicFormContent :fields="fields"></DynamicFormContent>
        </template>
      </DefaultModal>

      <DefaultModal
        :title="$t('delete_theme')"
        ref="deleteModal"
        @submit="confirmDeleteTheme"
        submit-button-text="delete"
        deleteModal
      >
        <template v-slot:content>
          {{ $t('remove_theme_confirmation') }} <b>{{ selectedTheme ? selectedTheme.theme_key || selectedTheme.theme_title : '' }}</b>?
        </template>
      </DefaultModal>

    </template>
  </DefaultCrudView>

</template>

<style scoped>
  .container {
    padding: 0;
  }

  .table-container {
    margin-top: 20px;
  }
</style>

<script>

import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

import DefaultCrudView from '@/components/DefaultCrudView.vue'
import DefaultModal from '@/components/DefaultModal.vue'
import DynamicFormContent from '@/components/DynamicFormContent.vue'

import TenantActions from '@/store/core/tenant/actions-types'
import ThemeActions from '@/store/core/theme/actions-types'
import AppActions from '@/store/app/actions-types'

import ThemeMutations from '@/store/core/theme/mutations-types'

import UserRoles from '@/store/core/user/user-roles'

export default {
  name: "Theme",

  components: {
    DefaultCrudView,
    DefaultModal,
    DynamicFormContent
  },

  computed: {
    ...mapGetters('app', [
      'getIconForCoreSettingsItem'
    ]),

    ...mapGetters('core/auth', [
      'checkAccessRights'
    ]),

    ...mapState({
      fields: state => state.core.theme.fields,
      tenants: state => state.core.tenant.tenants,
      selectedTheme: state => state.core.theme.selectedTheme,
    }),

    breadcrumbs() {
      return [
        {
          text: this.$t('themes'),
          prependIcon: this.getIconForCoreSettingsItem('themes'),
        },
      ]
    },

    isMaster() {
      return this.checkAccessRights([UserRoles.USER_MASTER])
    },
  },

  data() {
    return {
      loading: false,
      themes: [],
      options: {
        sortBy: ['theme_id'],
        sortDesc: [false],
      },
      itemsLength: 0,
      headers: [
        {text: 'Key', value: 'theme_key'},
        {text: 'Title', value: 'theme_title'},
        {text: 'Tenant', value: 'tenant_id'},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
      actions: [
        {
          text: "create_theme",
          eventToEmit: "create-new",
        }
      ]
    }
  },

  mounted() {
    this[TenantActions.GET_ALL_TENANTS]()
      .then(() => {
        this[ThemeMutations.SET_FIELD_TENANTS](this.tenants)

        this.fetchData()
      })
  },

  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE
    ]),

    ...mapActions('core/tenant', [
      TenantActions.GET_ALL_TENANTS
    ]),

    ...mapActions('core/theme', [
      ThemeActions.GET_THEMES_AS_ADMIN,
      ThemeActions.SAVE_THEME,
      ThemeActions.DELETE_THEME,
    ]),

    ...mapMutations('core/theme', [
      ThemeMutations.SET_SELECTED_THEME,
      ThemeMutations.SET_FIELD_TENANTS,
    ]),

    fetchData() {
      let options = {}

      if (this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      options.limit = this.options.itemsPerPage
      options.offset = this.options.itemsPerPage * (this.options.page - 1)

      this.loading = true
      this[ThemeActions.GET_THEMES_AS_ADMIN](options)
        .then((result) => {
          this.themes = [...result.items]

          this.itemsLength = result.total

          this.loading = false
        })
    },

    newTheme() {
      this[ThemeMutations.SET_SELECTED_THEME]({theme: null, tenants: this.tenants, isMaster: this.isMaster})
      this.$refs.modal.openModal()
    },

    editTheme(theme) {
      this[ThemeMutations.SET_SELECTED_THEME]({theme, tenants: this.tenants, isMaster: this.isMaster})
      this.$refs.modal.openModal()
    },

    save() {
      this.$refs.modal.errorMessage = null
      this.$refs.modal.errorList = []

      this[ThemeActions.SAVE_THEME]()
        .then(() => {
          let message = this.selectedTheme ? 'Theme edited' : 'Theme created'

          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t(message))

          this.$refs.modal.closeModal()

          this.fetchData()
        })
    },

    modalDelete(theme) {
      this[ThemeMutations.SET_SELECTED_THEME]({theme, tenants: this.tenants, isMaster: this.isMaster})
      this.$refs.deleteModal.openModal()
    },

    confirmDeleteTheme() {
      this[ThemeActions.DELETE_THEME](this.selectedTheme.theme_id)
        .then(() => {
          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('theme_removed_successfully'))

          this.$refs.deleteModal.closeModal()

          this.fetchData()
        })
        .catch(() => this.$refs.deleteModal.submitting = false)
    },

    getModalTitle() {
      return this.selectedTheme ? this.$t('edit_theme_index', {index: this.selectedTheme.theme_id}) : this.$t('new_theme')
    },

    getTenantTitle(id) {
      let tenant = this.tenants.find(f => f.tenant_id == id)

      return tenant ? tenant.title : ''
    },
  }
}

</script>